import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import PropertyIMG1 from "../../../images/search-result/property-img.jpg";
import _ from "lodash";
import { SaveItem } from "@starberry/myaccount-website-utils";

const PropertyList = (item, props) => {
    const [toggler, setToggler] = useState(false);
    const [floorToggler, setFloorToggler] = useState(false);
    const formatter = (val) => {
        if (val) {
            return "£" + new Intl.NumberFormat("en-US", {}).format(val);
        } else {
            return "£0"
        }

    }
    const priceTag = (val) => {
        if (!_.isEmpty(val)) {
            return _.startCase(val.split(/(?=[A-Z])/).join(' '));
        } else {
            return ""
        }
    }

    let uriStr = "";

    if (item.search_type === "sales") {
        uriStr = `property-for-sale/`
    } else if (item.search_type === "lettings") {
        uriStr = `property-to-rent/`
    }

    // property details url structure 
    // const image_url = item.images[0].srcUrl
    let processedImages = JSON.stringify({});
    if (item?.imagetransforms?.images_Transforms) {
        processedImages = item.imagetransforms.images_Transforms;
    }
    return (
        <React.Fragment>

            <div className="tab-img img-zoom">
                <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                    {item.images && item.images.length > 0 ?
                        <picture>
                            <img src={item.images[0].srcUrl} alt={item.title} />
                        </picture>
                        :

                        <picture>
                            <img src={PropertyIMG1} alt={item.title} />
                        </picture>
                    }
                </Link>
                <div className="property-btns">
                    {item.floorplan && item.floorplan.length > 0 &&
                        <Button type="button" variant="link" className="floor-plans" onClick={() => { item.lightBox(`${item.type}_floor_${item.index}`) }}>
                            <i className="icon-floor"></i>
                        </Button>
                    }
                    {item.images && item.images.length > 0 &&
                        <Button type="button" variant="link" className="photos_box" onClick={() => { item.lightBox(`${item.type}_image_${item.index}`) }}>
                            <i className="icon-camera"></i>
                            <span>1/{item.images.length}</span>
                        </Button>
                    }
                </div>

                <SaveItem type="property" pid={props.objectID} userObjects={props?.userObjects}>
                    <span className="btn-circle-list">
                        <i class="icon-like icon-default"></i>
                        <i class="icon-like-white-fill icon-saved"></i>
                    </span>
                </SaveItem>
            </div>

            <div className="tab-info">
                <span className="sm-text">
                    {_.startCase(_.toLower(item.title).replace(/-/g, " ")).replace(/In/g, "in")}

                </span>
                <h2>
                    <Link to={'/' + uriStr + item.slug + '-' + item.id}>
                        {item.display_address}
                    </Link>
                </h2>
                <h3>
                    {priceTag(item.price_qualifier)} {formatter(item.price)} {item.search_type === "lettings" ? "Per Calendar Month" : ""}
                </h3>
            </div>
        </React.Fragment>
    )
}
export default PropertyList